import React, { useState } from 'react';

// @ts-ignore
import axios from 'axios';
import Collapsible from 'react-collapsible';
import XMLParser from 'react-xml-parser';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faFileAlt } from '@fortawesome/free-solid-svg-icons';

import Layout from 'components/Layout';
import Modal from 'components/Modal';
import CollapsibleListItem from 'components/CollapsibleListItem';
import productData from 'data/products.json';

import { returnSources } from 'lib/metadata';
import LandScanSplash from '../assets/images/landscan_imagery_BG.png';

const MetaDataPage = () => {
  const [finalMetadata, setFinalMetadata] = useState({
    contact: '',
    address: '',
    onlineResource: '',
    standardName: '',
    standardVersion: '',
    title: '',
    publicationDate: '',
    creationDate: '',
    responsibleParty: '',
    otherCitationDetails: '',
    abstract: '',
    purpose: '',
    credit: '',
    status: '',
    keyword: '',
    descriptiveKeywords: [],
    language: '',
    extent: '',
    geographicBoundingBox: [],
    source: ''
  });
  const [modalOpen, setModalOpen] = useState(false);

  // parses xml and returns the data
  const baseUrl = `https://${process.env.CNAME}/metadata`;

  async function parseXml (product, variation) {
    const xml = new XMLParser();
    const { country, version, year, region } = variation;
    const urlCountry = country;
    let apiUrl = '';
    if (product === 'global') {
      apiUrl = `${baseUrl}/${product}/${year}/lsglobal_${year}.xml`;
    }
    if (product === 'hd') {
      apiUrl = `${baseUrl}/${product}/${urlCountry}/${version}/${urlCountry}_${version}-0.xml`;
    }
    if (product === 'us') {
      apiUrl = `${baseUrl}/${product}a/${year}/${region}.xml`;
    }
    const res = await axios.get(apiUrl);
    const data = await xml.parseFromString(res.data);
    return data;
  }

  async function handleViewMetadata (
    product = '',
    variation = {
      country: '',
      version: '',
      year: 2020,
      time: '',
      region: ''
    }
  ) {
    await convertXmlToMetadata(product, variation);
    setModalOpen(true);
  }

  // parses metadata and returns finalMetadata
  function parseMetadata (metadata = {}) {
    // Grab some key fields first...
    const contact = metadata.getElementsByTagName('contact')[0].children[0]
      .children[0].children[0].value;
    const address = metadata.getElementsByTagName('CI_Address')[0].children[0]
      .children[0].value;
    const sourcesList = returnSources(metadata).filter(items => !!items);
    let boundingBox = metadata.getElementsByTagName('EX_GeographicBoundingBox');
    boundingBox = boundingBox[0].children.map(child => child.children[0].value);
    const temporalExtent = metadata.getElementsByTagName('EX_TemporalExtent');

    // Build the object to render on page
    const parsedMetadata = {
      contact: contact,
      address: address,
      onlineResource:
        metadata.children[2].children[0].children[1].children[0].children[1]
          .children[0].children[0].children[0].value,
      standardName: metadata.children[4].children[0].value,
      standardVersion: metadata.children[5].children[0].value,
      title:
        metadata.children[6].children[0].children[0].children[0].children[0]
          .children[0].value,
      publicationDate:
        metadata.children[6].children[0].children[0].children[0].children[1]
          .children[0].children[0].children[0].value,
      creationDate:
        metadata.children[6].children[0].children[0].children[0].children[2]
          .children[0].children[0].children[0].value,
      responsibleParty:
        metadata?.children[6]?.children[0]?.children[0]?.children[0]
          ?.children[3]?.children[0]?.children[0]?.children[0]?.value,
      otherCitationDetails:
        metadata.children[6].children[0].children[0].children[0].children[5]
          .children[0].value,
      abstract: metadata.children[6].children[0].children[1].children[0].value,
      purpose: metadata.children[6].children[0].children[2].children[0].value,
      credit: metadata.children[6].children[0].children[3].children[0].value,
      status: metadata.children[6].children[0].children[4].children[0].value,
      keyword:
        metadata.children[6].children[0].children[7].children[0].children[0]
          .children[0].value,
      descriptiveKeywords: metadata?.children[6]?.children[0]?.children[6]
        ?.children[0]?.children?.length && [
        metadata?.children[6]?.children[0]?.children[6]?.children[0]?.children.map(
          item => ` ${item.children[0].value}`
        )
      ],
      language: metadata.children[0].children[0].value,
      extent: temporalExtent[0].children[0].children[0].children[0].value,
      geographicBoundingBox: boundingBox,
      source: sourcesList
    };
    setFinalMetadata(parsedMetadata);
  }

  // runs callback functions to parse xml and create metadata object
  async function convertXmlToMetadata (product, variation) {
    const metadata = await parseXml(product, variation);
    parseMetadata(metadata);
  }

  const hdList = productData.hdCountryOptions.map((country, i) => (
    <CollapsibleListItem
      label={`LandScan HD ${country.label} ${
        country.version ? country.version : ''
      }`}
      icon={<FontAwesomeIcon icon={faFileAlt} />}
      key={`${i}-${country}`}
      handleClick={() =>
        handleViewMetadata('hd', {
          country: country.longname,
          version: country.version ? country.version : 'v1',
          year: 2020,
          time: '',
          region: ''
        })
      }
    />
  ));

  const globalList = productData.yearOptions.map((year, i) => (
    <CollapsibleListItem
      label={`LandScan Global (${year.value})`}
      icon={<FontAwesomeIcon icon={faFileAlt} />}
      key={`${i}-${year}`}
      handleClick={() =>
        handleViewMetadata('global', {
          country: 'global',
          version: '',
          year: parseInt(`${year.value}`),
          time: '',
          region: ''
        })
      }
    />
  ));

  // Generating a custom, shortened list of US Day just for 2016-2018
  const shortenedUSList = productData.usRegions.filter(({ value }) =>
    productData.usRegions2016to2018.includes(value)
  );
  const us2016to2018 = [2018, 2017, 2016].map(year =>
    shortenedUSList.map(({ label, value, time }, i) => (
      <CollapsibleListItem
        label={`Landscan USA (${label} - ${year})`}
        icon={<FontAwesomeIcon icon={faFileAlt} />}
        key={`${i}-${value}`}
        handleClick={() =>
          handleViewMetadata('us', {
            country: '',
            version: '',
            year: year,
            time: time,
            region: `${value}`
          })
        }
      />
    ))
  );

  const us2019andUp = [2021, 2020, 2019].map(year =>
    productData.usRegions.map(({ label, value, time }, i) => (
      <CollapsibleListItem
        label={`Landscan USA (${label} - ${year})`}
        icon={<FontAwesomeIcon icon={faFileAlt} />}
        key={`${i}-${value}`}
        handleClick={() =>
          handleViewMetadata('us', {
            country: '',
            version: '',
            year: year,
            time: time,
            region: `${value}`
          })
        }
      />
    ))
  );

  return (
    <Layout>
      <section className="subpage">
        <div
          className="subpage__header"
          style={{
            backgroundImage: `url(${LandScanSplash})`
          }}
        >
          <h1>Metadata</h1>
        </div>
        <div className="subpage__content">
          <Collapsible
            className="collapsible-panel global-panel"
            trigger={[
              'LandScan Global Metadata',
              <FontAwesomeIcon key={1} icon={faChevronDown} />
            ]}
          >
            {globalList}
          </Collapsible>
          <Collapsible
            className="collapsible-panel hd-panel"
            trigger={[
              'LandScan HD Metadata',
              <FontAwesomeIcon key={2} icon={faChevronDown} />
            ]}
          >
            {hdList}
          </Collapsible>
          <Collapsible
            className="collapsible-panel us-panel"
            trigger={[
              'LandScan USA Metadata',
              <FontAwesomeIcon key={3} icon={faChevronDown} />
            ]}
          >
            {us2019andUp}
            {us2016to2018}
          </Collapsible>
        </div>
        {/* if toggle is true display the modal with the metadata on it */}
        {modalOpen && (
          <Modal
            className="metadata"
            isOpen={modalOpen}
            toggleModal={() => setModalOpen(false)}
            header={<h2>{finalMetadata.title.toUpperCase()}</h2>}
          >
            <p>
              <strong>Contact:</strong> {finalMetadata.contact}
            </p>
            <p>
              <strong>Address:</strong> {finalMetadata.address}
            </p>
            <p>
              <strong>Online Resource:</strong> {finalMetadata.onlineResource}
            </p>
            <p>
              <strong>Standard Name:</strong> {finalMetadata.standardName}
            </p>
            <p>
              <strong>Standard Version:</strong> {finalMetadata.standardVersion}
            </p>
            <p>
              <strong>Title:</strong> {finalMetadata.title}
            </p>
            <p>
              <strong>Publication Date:</strong> {finalMetadata.publicationDate}
            </p>
            <p>
              <strong>Creation Date:</strong> {finalMetadata.creationDate}
            </p>
            {finalMetadata.responsibleParty && (
              <p>
                <strong>Responsible Party:</strong>{' '}
                {finalMetadata.responsibleParty}
              </p>
            )}
            <p>
              <strong>Other Citation Details:</strong>{' '}
              {finalMetadata.otherCitationDetails}
            </p>
            <p>
              <strong>Abstract:</strong> {finalMetadata.abstract}
            </p>
            <p>
              <strong>Purpose:</strong> {finalMetadata.purpose}
            </p>
            <p>
              <strong>Credit:</strong> {finalMetadata.credit}
            </p>
            {finalMetadata.status && (
              <p>
                <strong>Status:</strong> {finalMetadata.status}
              </p>
            )}
            <p>
              <strong>Keyword:</strong> {finalMetadata.keyword}
            </p>
            {finalMetadata.descriptiveKeywords?.length && (
              <p>
                <strong>Descriptive Keywords:</strong>{' '}
                {finalMetadata.descriptiveKeywords.join(', ')}
              </p>
            )}
            <p>
              <strong>Language:</strong> {finalMetadata.language}
            </p>
            {finalMetadata.extent && (
              <p>
                <strong>Extent:</strong> {finalMetadata.extent}
              </p>
            )}
            {finalMetadata.geographicBoundingBox?.length && (
              <p>
                <strong>Geographic Bounding Box:</strong>
                <br />
                Westbound Longitude: {finalMetadata.geographicBoundingBox[0]}
                <br />
                Eastbound Longitude: {finalMetadata.geographicBoundingBox[1]}
                <br />
                Southbound Longitude: {finalMetadata.geographicBoundingBox[2]}
                <br />
                Northbound Longitude: {finalMetadata.geographicBoundingBox[3]}
              </p>
            )}
            <div className="sources">
              <h4>Source(s):</h4>
              {finalMetadata.source.map((sources, i) => (
                <div key={i} className="single-source">
                  {sources.map((source, i) => {
                    if (!source) {
                      return null;
                    } else {
                      const { label, value } = source;

                      if (label && value) {
                        return (
                          <div key={value + i}>
                            {label === 'title' ? (
                              <strong className="source-label">{value}</strong>
                            ) : (
                              <>
                                <span className="capitalize">{label}:</span>{' '}
                                {value}
                              </>
                            )}
                          </div>
                        );
                      } else {
                        return null;
                      }
                    }
                  })}
                </div>
              ))}
            </div>
          </Modal>
        )}
      </section>
    </Layout>
  );
};

export default MetaDataPage;
