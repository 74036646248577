// Pass in the XML metadata and return an array of citations objects to display
export function returnSources (xml) {
  const citations = xml.getElementsByTagName('CI_Citation');
  if (!citations || !citations.length) return;

  const rawSources = citations.map(citation => {
    if (!citation || !citation.children?.length) return null;
    return citation.children;
  });

  const sourceList = rawSources.map(source => {
    return source.map(child => {
      let sourceValue;
      let sourceLabel;

      // Titles are simple enough to get...
      if (child.name === 'title') {
        sourceLabel = child.name;
        sourceValue = child.children[0].value;
      } else if (child.children[0]?.children?.length) {
        // Some conditional logic for nested sources
        const citationPair = child.children[0].children;
        // Handle the different citation types (ie. originator, distributor, etc.)
        if (citationPair[0].name === 'organisationName') {
          if (citationPair[1].children[0].value) {
            sourceLabel = citationPair[1].children[0].value;
            sourceValue = citationPair[0].children[0].value;
          }
        }
        // Handle diff date types to display
        if (citationPair[0].name === 'date') {
          if (citationPair[1].children[0].value) {
            sourceLabel = citationPair[1].children[0].value + ' date';
            sourceValue = citationPair[0].children[0].value;
          }
        }
      }
      if (sourceLabel && sourceValue) {
        return {
          label: sourceLabel,
          value: sourceValue
        };
      } else {
        return null;
      }
    });
  });

  return sourceList;
}
